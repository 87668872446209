import type { PageComponentType } from '@libs-components/types'
import { useRouter } from 'next/router'
import { useLayoutEffect } from 'react'
import { isBrandModeInit, setIsBrandModeInit, setBrandInfo } from '@wordup/lib-shop/utils'

type UseModeInitType = { pageComponentResponse?: PageComponentType.ResponseType }

const BRAND_PAGENAME = '/brand/[pageKey]' as const

const useModeInit = ({ pageComponentResponse }: UseModeInitType = {}) => {
  const router = useRouter()
  useLayoutEffect(() => {
    if (isBrandModeInit()) return

    const isLandedOnBrandPage = router.pathname === BRAND_PAGENAME
    if (
      isLandedOnBrandPage &&
      pageComponentResponse &&
      pageComponentResponse.meta.org &&
      pageComponentResponse.meta.org.whiteLabelEnabled
    ) {
      setBrandInfo({
        whiteLabelEnabled: pageComponentResponse.meta.org.whiteLabelEnabled,
        orgId: pageComponentResponse.meta.org.id,
        logoUrl: pageComponentResponse.meta.org.logoUrl,
        name: pageComponentResponse.meta.org.name,
        taxIdNumber: pageComponentResponse.meta.org.taxIdNumber,
        pageKey: pageComponentResponse.meta.page.attributes.key,
      })
    }

    setIsBrandModeInit()
  }, [
    router,
    pageComponentResponse?.meta?.org?.whiteLabelEnabled,
    pageComponentResponse?.meta?.org?.id,
    pageComponentResponse?.meta?.org?.logoUrl,
    pageComponentResponse?.meta?.org?.name,
    pageComponentResponse?.meta?.org?.taxIdNumber,
    pageComponentResponse?.meta?.page?.attributes?.key,
  ])
}

export { useModeInit }

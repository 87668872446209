import { apiCaller } from '@wordup/lib-shop/features'
import { wrapDeprecatedConsole } from '@libs-components/utils/console'

/**
 * @deprecated The fire was moved from `@/apis/utils/caller` to `@wordup/lib-shop/features/api`.
 *
 * You should use `import { apiCaller } from '@wordup/lib-shop/features'`
 **/
export default wrapDeprecatedConsole(apiCaller, [
  "You should use `import { apiCaller } from '@wordup/lib-shop/features'`",
])

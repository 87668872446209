import type { FooterInfoType } from './types'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
import { useCallback, useMemo, useState } from 'react'
import { noop } from 'lodash-es'

import AppStoreLink from '../link/app-download-link'
import { Text } from '../text'
import DumplingPopcornSilhouette from './components/dumpling-popcorn-silhouette'
import LinkSection from './components/link-section'

import getAboutUs from './sections/about-us'
import getProductAndService from './sections/product-and-service'
import getLanguageLearning from './sections/language-learning'
import getExamPreparation from './sections/exam-preparation'
import getPartners from './sections/partners'

const darkModeLogoUrl = 'https://public.wordup.com.tw/logo_temp_darkmode.png' as const

const FaqModal = dynamic(import('./components/faq-modal'), { ssr: false })

type FooterProps = FooterInfoType & {
  isWhiteLabelMode: boolean
  openIntercomMessenger?: () => void
}

const Footer = ({
  orgName,
  taxIdNumber,
  isWhiteLabelMode,
  openIntercomMessenger = noop,
}: FooterProps) => {
  const { t } = useTranslation('common')
  const [showModal, setShowModal] = useState(false)

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const linkSectionsData = useMemo(
    () => [
      getAboutUs({ faqClick: openModal, contactUsClick: openIntercomMessenger }),
      getProductAndService(),
      getLanguageLearning(),
      getExamPreparation(),
      getPartners(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, openModal, openIntercomMessenger],
  )
  const lastSectionIndex = linkSectionsData.length - 1
  const isEvenSections = linkSectionsData.length % 2 === 0

  return (
    <footer className='bg-grayscale-900 relative'>
      {isWhiteLabelMode ? null : (
        <div className='mobileUp:grid-cols-[auto_124px_124px_124px_124px_124px] mobileUp:grid-rows-[auto_1fr] relative grid grid-cols-[1fr_1fr]'>
          <div className='bg-grayscale-800 mobileUp:col-span-1 mobileUp:px-[38px] mobileUp:pt-[26px] mobileUp:pb-0 col-span-2 flex w-full flex-col gap-3 px-[20px] pb-4 pt-[26px]'>
            <Image
              src={darkModeLogoUrl}
              alt='logo'
              width={190.625}
              height={25}
              className='h-auto max-h-[25px] max-w-full'
            />

            <Text
              variant='label_s_400'
              color='grayscale-400'
              className='mobileUp:max-w-[455px] text-justify'
            >
              {t('footer.company_description')}
            </Text>
          </div>

          <div className='bg-grayscale-900 mobileUp:col-span-1 mobileUp:row-start-2 mobileUp:justify-start mobileUp:bg-grayscale-800 mobileUp:p-[12px_38px] col-span-2 row-start-5 flex h-full items-start justify-center gap-3 p-[24px_24px_0px]'>
            <AppStoreLink platform='android' />
            <AppStoreLink platform='ios' />
          </div>

          {linkSectionsData.map((section, idx) => {
            const shouldTakeFullColSpan = idx === lastSectionIndex && !isEvenSections

            return (
              <div
                key={`${idx}_${section.titleI18Key}`}
                className={twMerge(
                  'bg-grayscale-800',
                  'border-grayscale-700 border-0 border-t border-solid',
                  'mobileUp:p-[26px_16px_26px_0px] px-5 py-4',
                  'mobileUp:col-span-1 mobileUp:row-span-2',
                  shouldTakeFullColSpan ? 'col-span-2' : 'col-span-1',
                )}
              >
                <LinkSection section={section} />
              </div>
            )
          })}
        </div>
      )}

      <div className='px-10 py-5'>
        <div className='mobileUp:flex-row flex flex-col items-center justify-between gap-y-1.5'>
          <Text variant='label_s_400' color='grayscale-000'>
            © {new Date().getFullYear()} {orgName}・{t('footer.tax_number')} {taxIdNumber}
          </Text>
          {isWhiteLabelMode && (
            <Text variant='label_s_400' color='grayscale-400' className='mobileUp:ml-auto'>
              Made with by 聰明學習 WORD UP
            </Text>
          )}
        </div>
      </div>

      {showModal && <FaqModal open onClose={closeModal} />}

      <DumplingPopcornSilhouette />
    </footer>
  )
}

export { Footer, type FooterProps }

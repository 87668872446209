import type { PropsWithChildren, ReactNode } from 'react'
import { useIsWhiteLabelMode } from './use-brand-info'

type ModeBasedRendererProps = PropsWithChildren<
  Partial<{ BrandComponent: () => ReactNode; isWhiteLabelModeFromServer: boolean }>
>

const ModeBasedRenderer = ({
  children,
  isWhiteLabelModeFromServer,
  BrandComponent,
}: ModeBasedRendererProps) => {
  const isWhiteLabelMode = useIsWhiteLabelMode(isWhiteLabelModeFromServer)

  if (!isWhiteLabelMode) return children

  if (BrandComponent) return <BrandComponent />

  return null
}

const ShowOnWhiteLabelMode = ({ children }: PropsWithChildren) => {
  const isWhiteLabelMode = useIsWhiteLabelMode()
  return isWhiteLabelMode ? children : null
}

const HideForWhiteLabelMode = ({ children }: PropsWithChildren) => {
  const isWhiteLabelMode = useIsWhiteLabelMode()
  return isWhiteLabelMode ? null : children
}

export { ModeBasedRenderer, ShowOnWhiteLabelMode, HideForWhiteLabelMode }

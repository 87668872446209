import PackageInfo from 'package.json'
import { isEmpty } from 'lodash-es'
import { i18n } from 'next-i18next'
import {
  createApiCaller,
  type CreateAxiosInstanceParams,
  type ApiCallerConfigType,
} from '@wordup/apis'
import Swal from '@libs-components/components/sweetalert'
import { isWuAppWebview, getWuAppPlatformInfo } from '@libs-components/utils/device'
import { credentialManager } from '../../utils'
import { isExpectedError } from './is-expected-error'
import { sendUnexpectedErrorNotify } from './send-unexpected-error-notify'

const onResponseError: CreateAxiosInstanceParams['onResponseError'] = async error => {
  const { isExpected, responseErrorDetail, responseErrorCode } = isExpectedError(error)
  if (isExpected) {
    const shouldThrowError = !error?.config?.ignoreThrowError || !error?.config?.isIgnoreError
    return shouldThrowError ? Promise.reject(error) : undefined
  }
  sendUnexpectedErrorNotify(error, { responseErrorDetail, responseErrorCode })
  return Promise.reject(error)
}

const _apiCaller = createApiCaller({
  initialConfig: {
    baseURL: process.env.NEXT_PUBLIC_API_DOMAIN,
    headers: {
      'Client-Info': `shop/${PackageInfo.version}`,
      'Client-Lang': 'zh-TW',
      'End-User-Client-Info': 'N/A',
    },
  },
  getAuthCredentials: credentialManager.get,
  onResponseError,
})

export const apiCaller = <T>(config: ApiCallerConfigType<T>) => {
  const authCredentials = credentialManager.get()
  if ((config.requiresLogin || config.isWithAuth) && isEmpty(authCredentials)) {
    if (i18n) {
      Swal.fire({
        icon: 'info',
        title: i18n.t('common.login_and_retry'),
        confirmButtonText: i18n.t('common.confirm'),
      })
    }
    return Promise.reject(new Error('user_not_logged_in'))
  }

  config.headers ||= {}

  if (i18n && i18n.resolvedLanguage) {
    config.headers['Client-Lang'] = i18n.resolvedLanguage
  }

  if (isWuAppWebview()) {
    const { platform, version } = getWuAppPlatformInfo()
    config.headers['End-User-Client-Info'] = `${platform}/${version}`
  }

  return _apiCaller(config)
}

import { strapiCaller } from '..'

const localeReMapping: Record<string, string> = {
  // en
  'en-us': 'en',

  // tw
  tw: 'zh-Hant-TW',
  'zh-tw': 'zh-Hant-TW',
}

export const getVersionedConfig = async ({
  key,
  locale,
}: {
  key: string
  locale?: string // valid options: 'en'. default is 'zh-Hant-TW'
}) => {
  const query = ['sort[version]=DESC', 'pagination[limit]=1', `filters[key][$eq]=${key}`]
  if (locale) {
    query.push(`locale=${localeReMapping[locale.toLowerCase()] || locale}`)
  }
  return await strapiCaller<StrapiVersionedConfigType.VersionedConfigsType>({
    url: `/api/versioned-configs?${query.join('&')}`,
  })
}

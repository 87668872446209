import { useLayoutEffect } from 'react'
import { useModeInit } from '@wordup/lib-shop/utils'

const useInit = () => {
  useModeInit()
  useLayoutEffect(() => {
    // Enable dark mode
    if (localStorage.getItem('isDarkMode') === 'true') {
      import('darkreader').then(DarkReader => {
        DarkReader.enable({ brightness: 90, contrast: 100, sepia: 0 })
      })
    }
  }, [])
}

export { useInit }

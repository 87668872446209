import type { PropsWithChildren } from 'react'
import { MDXProvider as _MDXProvider } from '@mdx-js/react'
import { useMDXComponents } from './mdx-components'

/**
 * @summary
 *    A wrapper component that provides global components to the MDXProvider.
 *
 *    This is not required; you can also write markdown pages without these components.
 *
 * @description
 *    Currently, we apply `withMDXProvider` on a per-page basis rather than globally in `_app` to:
 *
 *    1. Avoid increasing the `_app` bundle size.
 *    2. Restrict its usage to pages that utilize MD or MDX content.
 *
 * @usage Export it as the default for any pages (MD or MDX).
 *
 * @example
 *  - Template for using markdown in `*.page.mdx`:
 *    ```*.page.mdx
 *      import { withMDXProvider } from '@/components/markdown-provider';
 *
 *      <!--
 *       Add any MD or MDX content here where you can use globalComponents directly.
 *       This content will be passed as children to MDXProvider.
 *      -->
 *
 *      export default MDXProvider;
 *    ```
 *
 *  - Template for using markdown in `*.page.tsx`:
 *    Use it as usual, but remember to wrap the content with MDXProvider.
 **/
const MDXProvider = ({ children }: PropsWithChildren) => {
  const globalMDXComponents = useMDXComponents()
  return <_MDXProvider components={globalMDXComponents}>{children}</_MDXProvider>
}

export { MDXProvider }

import type { ReactNode } from 'react'
import { Skeleton } from '@libs-components/components/skeleton'
import { MDXRemote } from 'next-mdx-remote'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import WuDivider from '@libs-components/components/divider'
import { Text } from '@libs-components/components/text'
import { Header, HeaderProps } from '@libs-components/components/header'
import useSwrVersionedConfig from '@libs-components/features/third-party/strapi/versioned-config/use-swr-versioned-config'
import useFaqKiaoInsidePartnership from './use-faq-kiao-inside-partnership'
import { useBrandInfo } from '../../utils'

const logoUrl = 'https://public.wordup.com.tw/shop/assets/brand-mode-wu-logo.png'

type BrandFaqType = {
  headerProps: HeaderProps<'h1'> & { headerContainerClassName?: string }
  fqaKey: 'faq-kiao-inside-benefits' | 'faq-how-to-start-course'
  actionElement?: ReactNode
}

const BrandFaqSkeleton = () => (
  <div className='border-grayscale-100 border-0 border-b border-solid pb-4'>
    <Skeleton className='h-[200px]' />
  </div>
)

const BrandFaq = ({
  headerProps: { headerContainerClassName, ...headerProps },
  fqaKey,
  actionElement,
}: BrandFaqType) => {
  const { t } = useTranslation()
  const brandInfo = useBrandInfo()
  const { faqKiaoInsidePartnership } = useFaqKiaoInsidePartnership({ brand: brandInfo?.name || '' })

  const { mdxSource: mdxSourceMoreQuestionsContactUs } = useSwrVersionedConfig({
    key: 'faq-more-questions-contact-us',
    isMDX: true,
  })

  const { mdxSource: mdxSourceFqaKey } = useSwrVersionedConfig({
    key: fqaKey,
    isMDX: true,
  })

  return (
    <div className='flex w-full flex-col gap-4'>
      {headerContainerClassName ? (
        <div className={headerContainerClassName}>
          <Header {...headerProps} />
        </div>
      ) : (
        <Header {...headerProps} />
      )}

      <div className='m-auto w-full'>
        <div className='flex items-center justify-center gap-4'>
          <div className='border-grayscale-50 flex items-center justify-center rounded-full border-[1px] border-solid'>
            <Image
              src={logoUrl}
              width={60}
              height={60}
              alt='logo'
              priority
              className='h-auto min-w-[60px] max-w-full'
            />
          </div>

          <Text variant='body_m_500'>{t('level_up_with_wordup')}</Text>
        </div>
      </div>

      <WuDivider />

      {faqKiaoInsidePartnership ? (
        <MDXRemote {...faqKiaoInsidePartnership} />
      ) : (
        <BrandFaqSkeleton />
      )}

      {fqaKey ? mdxSourceFqaKey ? <MDXRemote {...mdxSourceFqaKey} /> : <BrandFaqSkeleton /> : null}

      {mdxSourceMoreQuestionsContactUs ? (
        <MDXRemote {...mdxSourceMoreQuestionsContactUs} />
      ) : (
        <BrandFaqSkeleton />
      )}

      {actionElement}
    </div>
  )
}

export { BrandFaq }

import { sessionStorageManager } from '../storage/session'
import { MODE_INIT_KEY, BRAND_INFO_KEY } from './constants'

/* Mode Initialization getter/setter */
const setIsBrandModeInit = () => {
  sessionStorageManager.set(MODE_INIT_KEY, 'true')
}

const isBrandModeInit = () => {
  return sessionStorageManager.get(MODE_INIT_KEY) === 'true'
}

/* Brand info getter/setter */
type BrandInfoType = {
  whiteLabelEnabled: boolean
  orgId: string
  name: string
  logoUrl: string
  taxIdNumber: string
  pageKey: string
}
type BrandInfoTypeKeys = keyof BrandInfoType

const getBrandInfo = () => {
  return sessionStorageManager.get<BrandInfoType | null>(BRAND_INFO_KEY, { isJSON: true })
}

const getBrandInfoByKey = <Keys extends BrandInfoTypeKeys>(key: Keys) => {
  const brandInfo = getBrandInfo()
  return brandInfo ? brandInfo[key] : null
}

const setBrandInfo = (brandInfo: BrandInfoType) => {
  sessionStorageManager.set(BRAND_INFO_KEY, JSON.stringify(brandInfo))
}

export type { BrandInfoType, BrandInfoTypeKeys }
export { setIsBrandModeInit, isBrandModeInit, getBrandInfo, getBrandInfoByKey, setBrandInfo }

import type { MouseEvent } from 'react'
import { useIsWhiteLabelMode, useBrandInfoByKey } from '@wordup/lib-shop/utils'
import { WORDUP_LOGO_URL } from '@wordup/lib-shop/constants/common'
import { Image } from '@libs-components/components/Image'
import { Icon } from '@libs-components/components/icon'

type AuthLogoProps = {
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void
}

// https://stackoverflow.com/questions/65169431/how-to-set-the-next-image-component-to-100-height
const responsiveImageStyle = {
  width: 0,
  height: 20,
  sizes: '100vw',
  style: { width: 'auto', height: '20px' },
} as const

const AuthLogo = ({ onClick }: AuthLogoProps) => {
  const isWhiteLabelMode = useIsWhiteLabelMode()
  const navbarLogoUrl = useBrandInfoByKey('logoUrl')

  return isWhiteLabelMode ? (
    <div
      className='bg-grayscale-50 mx-auto flex w-fit cursor-pointer items-center justify-center gap-x-1.5 rounded-full px-3 py-1.5'
      onClick={onClick}
    >
      <Image {...responsiveImageStyle} src={navbarLogoUrl} alt='brand logo' />
      <Icon type='close' color='grayscale-500' />
      <Image {...responsiveImageStyle} src={WORDUP_LOGO_URL} alt='wordup logo' />
    </div>
  ) : null
}

export { AuthLogo, type AuthLogoProps }

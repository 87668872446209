import type { PropsWithChildren } from 'react'
import useAuth from './hooks/use-auth'

const ShowOnLoggedIn = ({ children }: PropsWithChildren) => {
  const { isLoggedIn } = useAuth()
  return isLoggedIn ? children : null
}

const HideForLoggedIn = ({ children }: PropsWithChildren) => {
  const { isLoggedIn } = useAuth()
  return isLoggedIn ? null : children
}

export { ShowOnLoggedIn, HideForLoggedIn }

export const formatMoney = (
  value: string | number,
  overrideOpts: { locale?: string; digit?: number } = {},
) => {
  const options = { currency: 'TWD', ...overrideOpts }

  if (options.currency === 'TWD' && options.digit === undefined) {
    options.digit = 0
  }

  if (options.locale === undefined) {
    options.locale = 'zh-TW'
  }

  const moneyFormatter = new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.currency,
    maximumFractionDigits: options.digit ?? 0,
    minimumFractionDigits: options.digit ?? 0,
  })

  return 'NT' + moneyFormatter.format(+value)
}

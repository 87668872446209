import type { MDXComponents } from 'mdx/types'
import { useMemo } from 'react'
import { Text } from '../text'
import { Icon } from '../icon'
import { Image } from '../Image'

// Feel free to add more components here as needed,
// which will automatically apply to any md or mdx pages using MDXProvider.
const GLOBAL_COMPONENTS: MDXComponents = {
  Text,
  Icon,
  // @ts-expect-error -- using next/image instead of native img
  img: Image,
}

export const useMDXComponents = () => useMemo(() => GLOBAL_COMPONENTS, [])

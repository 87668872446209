import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { isWuAppWebview } from '@libs-components/utils/device'
import { SHOP_PATH } from '@libs-components/utils/routes'

const ShopNavBar = dynamic(
  import('@/components/layouts/common/components/shop-nav-bar').then(mod => mod.ShopNavBar),
  { ssr: false, loading: () => <div className='h-[60px]' /> },
)
const AppNavBar = dynamic(
  import('@/components/layouts/common/components/app-nav-bar').then(mod => mod.AppNavBar),
  { ssr: false, loading: () => <div className='h-[44px]' /> },
)

const NavBar = () => {
  const route = useRouter()
  const shouldHideNavbar = route.pathname.includes(SHOP_PATH.OUTCOME)

  if (shouldHideNavbar) return <div />

  if (isWuAppWebview()) {
    return <AppNavBar />
  }

  return <ShopNavBar />
}

export { NavBar }

import { useState } from 'react'
import dynamic from 'next/dynamic'
import { noop } from 'lodash-es'
import { useIntercomHider, useMobileUpMediaQuery } from '@libs-components/hooks'
import { AuthContent } from './auth-content'
import useAuth from './hooks/use-auth'

const Modal = dynamic(
  import('@libs-components/components/modal').then(mod => mod.WuModal),
  { ssr: false },
)
const Drawer = dynamic(
  import('@mui/material').then(mod => mod.SwipeableDrawer),
  { ssr: false },
)

const AuthFormPickers = () => {
  const isMobuileUp = useMobileUpMediaQuery()
  const [showWhiteLabelModeSlide, setShowWhiteLabelModeSlide] = useState(false)
  const { authModalIsOpen, closeAuthModal } = useAuth()
  useIntercomHider({ shouldHideIntercom: authModalIsOpen })

  const onClose = () => {
    closeAuthModal()
    setShowWhiteLabelModeSlide(false)
  }

  return isMobuileUp ? (
    <Modal
      open={authModalIsOpen}
      onClose={onClose}
      maxWidth='375px'
      maxHeight='80vh'
      padding='1rem 1.25rem'
      mainBlockClassName='rounded-[20px]'
    >
      <AuthContent
        showWhiteLabelModeSlide={showWhiteLabelModeSlide}
        setShowWhiteLabelModeSlide={setShowWhiteLabelModeSlide}
      />
    </Modal>
  ) : (
    <Drawer
      open={authModalIsOpen}
      onOpen={noop}
      onClose={onClose}
      anchor='right'
      disableBackdropTransition
      ModalProps={{ BackdropProps: { invisible: true } }}
      PaperProps={{
        className:
          'w-full h-[calc(100%-var(--browser-main-nav-height))] top-[var(--browser-main-nav-height)] p-5 pt-8 shadow-none border-t border-0 border-solid border-grayscale-100',
      }}
    >
      <AuthContent
        showWhiteLabelModeSlide={showWhiteLabelModeSlide}
        setShowWhiteLabelModeSlide={setShowWhiteLabelModeSlide}
      />
    </Drawer>
  )
}

export { AuthFormPickers }

import { useEffect, useState } from 'react'
import { isWuAppWebview } from '@libs-components/utils/device'

const useStickyTopClassName = () => {
  const [stickyTopClassName, setStickyTabsTopClassName] = useState('')

  useEffect(() => {
    setStickyTabsTopClassName(
      isWuAppWebview()
        ? 'top-[calc(var(--app-nav-height,44px)+var(--referral-header-height,0px)-2px)]'
        : 'top-[calc(var(--nav-top,var(--browser-main-nav-height))+var(--referral-header-height,0px)-2px)]',
    )
  }, [])

  return stickyTopClassName + ' transition-top ease-linear'
}

export { useStickyTopClassName }

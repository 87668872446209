import type { ReactNode } from 'react'
import NextImage from 'next/image'
import { twMerge } from 'tailwind-merge'
import { EMPTY_IMAGE_FALLBACK } from './constants'
import customLoader from './custom-loader'
import getImageUri from './get-image-uri'

type ImageProps = React.ComponentProps<typeof NextImage> & {
  fallbackSrc?: string
  skeleton?: ReactNode
}

const Image = ({
  src,
  fallbackSrc = EMPTY_IMAGE_FALLBACK,
  className,
  skeleton,
  ...props
}: ImageProps) => {
  // @ts-expect-error -- url is always string currently
  const imgUrl = getImageUri(src) || fallbackSrc

  // If a skeleton is provided and `imgUrl` is an empty fallback URL, display the skeleton instead of the image.
  if (skeleton && imgUrl === EMPTY_IMAGE_FALLBACK) {
    return skeleton
  }

  return (
    <NextImage
      {...props}
      src={imgUrl}
      className={twMerge('max-w-full', className)}
      loader={customLoader}
    />
  )
}

export { Image, type ImageProps }

import camelcaseKeys from 'camelcase-keys'
import useSWR from 'swr'
import { getVersionedConfig } from '.'
import { getMDXSource } from '../../../../components/markdown'

const useSwrVersionedConfig = (
  props: Pick<StrapiVersionedConfigType.VersionedConfigType['data']['attributes'], 'key'> & {
    isMDX?: boolean
  },
) => {
  const { data, isValidating } = useSWR(props, async props => {
    const data = await getVersionedConfig(props)
    const valueText = data?.data?.[0]?.attributes.valueText || ''

    const mdxSource = props?.isMDX ? await getMDXSource(valueText) : null

    return { strapiData: data?.data?.[0], mdxSource }
  })

  return { strapiData: data?.strapiData?.attributes, isValidating, mdxSource: data?.mdxSource }
}

export default useSwrVersionedConfig

import { createAuthInstance } from '@libs-components/utils/auth-utils'

/** @deprecated The utils was moved from `@/features/auth/utils` to `@libs-components/utils/auth-utils`. */

const {
  saveCredential,
  getCredential,
  checkHasCredential,
  getUserId,
  saveUserId,
  getUserName,
  saveUserName,
  getUserEmail,
  clearCookies,
} = createAuthInstance()

export default {
  saveCredential,
  saveUserId,
  saveUserName,
  getUserName,
  getUserId,
  getUserEmail,
  getCredential,
  clearCookies,
  checkHasCredential,
}

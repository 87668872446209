import type { Stage } from '@/types/env-stage'
import type { LangType } from '@/types/i18n'
import PackageInfo from 'package.json'
import { Slide } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { SHOP_PATH } from '@libs-components/utils/routes'
import { AuthForm } from '@libs-components/components/auth-form'
import { BrandFaq } from '@wordup/lib-shop/components'
import { withFullShopsUrl } from '@/libs/routes'
import { AuthLogo } from './auth-logo'
import useAuth from './hooks/use-auth'

const { version } = PackageInfo

type AuthContentProps = {
  showWhiteLabelModeSlide: boolean
  setShowWhiteLabelModeSlide: (show: boolean) => void
}

const AuthContent = ({ showWhiteLabelModeSlide, setShowWhiteLabelModeSlide }: AuthContentProps) => {
  const { t, i18n } = useTranslation()

  const {
    authLayout,
    setAuthLayout,
    onError,
    onRequest,
    onSuccess,
    onForgetPasswordSuccess,
    onForgetPasswordError,
  } = useAuth()

  const goBackToSignInOrSignUp = () => setShowWhiteLabelModeSlide(false)

  return (
    <>
      <Slide direction='left' in={showWhiteLabelModeSlide} timeout={500} mountOnEnter unmountOnExit>
        <div>
          <BrandFaq
            fqaKey='faq-kiao-inside-benefits'
            headerProps={{
              headerContainerClassName: 'sticky z-[49] bg-white top-0',
              breadcrumbItems: [
                { text: t('back_to_signin_signup_page'), onClick: goBackToSignInOrSignUp },
              ],
              prevButtonProps: { onClick: goBackToSignInOrSignUp },
            }}
          />
        </div>
      </Slide>
      <AuthForm
        className={showWhiteLabelModeSlide ? 'hidden' : undefined}
        logo={<AuthLogo onClick={() => setShowWhiteLabelModeSlide(true)} />}
        lang={i18n.resolvedLanguage as LangType}
        stage={process.env.NEXT_PUBLIC_STAGE as Stage}
        apiDomain={process.env.NEXT_PUBLIC_API_DOMAIN as string}
        clientInfo={`shop/${version}`}
        layout={authLayout}
        setLayout={setAuthLayout}
        domain={process.env.NEXT_PUBLIC_ROOT_DOMAIN as string}
        googleClientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        fbAppId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        onRequest={onRequest}
        onSuccess={onSuccess}
        onError={onError}
        appleRedirectUri={process.env.NEXT_PUBLIC_SELF_DOMAIN}
        termUrl={withFullShopsUrl(SHOP_PATH.TERMS_OF_SERVICE)}
        forgetPasswordLink={`${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/auth/reset-password`}
        onforgetPasswordSuccess={onForgetPasswordSuccess}
        onforgetPasswordError={onForgetPasswordError}
      />
    </>
  )
}

export { AuthContent }

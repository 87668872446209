import { Footer as _Footer } from '@libs-components/components/footer'
import { useWuIntercom } from '@libs-components/hooks'
import { useBrandInfo, useIsWhiteLabelMode } from '@wordup/lib-shop/utils'

const Footer = () => {
  const brandInfo = useBrandInfo()
  const isWhiteLabelMode = useIsWhiteLabelMode()
  const { openIntercomMessenger } = useWuIntercom()
  return (
    <_Footer
      orgName={brandInfo.name}
      taxIdNumber={brandInfo.taxIdNumber}
      isWhiteLabelMode={isWhiteLabelMode}
      openIntercomMessenger={openIntercomMessenger}
    />
  )
}

export { Footer }

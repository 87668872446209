import useSwrVersionedConfig from '@libs-components/features/third-party/strapi/versioned-config/use-swr-versioned-config'
import { getMDXSource, MDXRemoteSerializeResult } from '@libs-components/components/markdown'
import { useEffect, useState } from 'react'

type FaqKiaoInsidePartnershipType = {
  brand: string
}

const useFaqKiaoInsidePartnership = ({ brand }: FaqKiaoInsidePartnershipType) => {
  const { strapiData } = useSwrVersionedConfig({
    key: 'faq-kiao-inside-partnership',
  })
  const [mdxSource, setMdxSource] = useState<MDXRemoteSerializeResult | null>(null)

  useEffect(() => {
    if (!strapiData?.valueText) return

    const getFaqKiaoInsidePartnership = async () => {
      try {
        const replaceBrand = strapiData.valueText.replace(/#brand/g, brand)

        if (!replaceBrand) return

        const mdxSource = await getMDXSource(replaceBrand)

        setMdxSource(mdxSource)
      } catch (error) {
        console.error('Error fetching MDX Source:', error)
      }
    }

    getFaqKiaoInsidePartnership()
  }, [strapiData?.valueText, brand])

  return { faqKiaoInsidePartnership: mdxSource }
}

export default useFaqKiaoInsidePartnership

import camelcaseKeys from 'camelcase-keys'
import { getQueryString } from '../../../utils/url'

type FetchParams = {
  url: string
  params?: Record<string, string | number | boolean>
  options?: RequestInit
}

const strapiCaller = async <T>({ url, params, options }: FetchParams): Promise<T | null> => {
  const baseUrl = process.env.NEXT_PUBLIC_STRAPI_DOMAIN || ''

  const fullUrl = `${baseUrl}${url}${getQueryString({ obj: params || {} })}`

  try {
    const response = await fetch(fullUrl, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
      },
    })

    if (!response.ok) throw new Error('strapi fetch error')

    return camelcaseKeys(await response.json(), { deep: true })
  } catch (error) {
    return null
  }
}

export { strapiCaller }

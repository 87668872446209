import { atom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { WORDUP_LOGO_URL } from '../../constants/common'
import { getBrandInfo, type BrandInfoType, type BrandInfoTypeKeys } from './utils'

const brandInfoAtom = atom<BrandInfoType>({
  whiteLabelEnabled: false,
  orgId: '',
  name: '聰明學習股份有限公司',
  taxIdNumber: '82816072',
  logoUrl: WORDUP_LOGO_URL,
  pageKey: '',
})
brandInfoAtom.onMount = setAtom => {
  const brandInfo = getBrandInfo()
  if (brandInfo) setAtom(brandInfo)
}

const useBrandInfo = () => useAtomValue(brandInfoAtom)

const useBrandInfoByKey = <Keys extends BrandInfoTypeKeys>(
  key: Keys,
  initialValueFromServerForSSR?: BrandInfoType[Keys],
) => {
  const [isInitialRender, setIsInitialRender] = useState(true)
  const brandInfoFromClient = useBrandInfo()
  useEffect(() => {
    setIsInitialRender(false)
  }, [])
  if (typeof initialValueFromServerForSSR !== 'undefined' && isInitialRender) {
    return initialValueFromServerForSSR
  }
  return brandInfoFromClient[key]
}

const useIsWhiteLabelMode = (initialValueFromServer?: BrandInfoType['whiteLabelEnabled']) => {
  return useBrandInfoByKey('whiteLabelEnabled', initialValueFromServer)
}

export { useBrandInfo, useBrandInfoByKey, useIsWhiteLabelMode }

import { ToastContainer } from 'react-toastify'
import { Icon } from '../icon'
import './styles.css'

const WuToastContainer = () => {
  return (
    <ToastContainer
      className='wu-toast-container'
      position='bottom-right'
      pauseOnHover
      closeOnClick
      draggable={false}
      autoClose={5000}
      pauseOnFocusLoss
      closeButton={CloseButton}
    />
  )
}

export default WuToastContainer

const CloseButton = () => <Icon type='close' size={20} className='my-auto' color='grayscale-800' />

import type { MoneybackMissionsResponseType } from '@wordup/apis'
import { apiCaller } from '@wordup/lib-shop/features'
import useSWR from 'swr'

type Params = { productId: string }

const useMoneybackMissions = (params: Params) => {
  const fetcher = async ({ productId }: Params) => {
    const { data: moneybackMission } = await apiCaller<MoneybackMissionsResponseType>({
      url: `/v1/shop/products/${productId}/moneyback_missions`,
    })
    return moneybackMission
  }
  return useSWR(params, fetcher, { keepPreviousData: true })
}

export { useMoneybackMissions }
